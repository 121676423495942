import { UsergroupDeleteOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
// import { useRouter } from "next/router";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const Boxes3 = () => {
  const navigate = useNavigate();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );

  return (
    <Row gutter={[0, 20]}>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd3  m-5 cursor-pointer"
          onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              {" "}
              {deshboard?.ActiveUser ? deshboard?.ActiveUser : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Active User</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  userd userd4  m-5 cursor-pointer"
          // onClick={() => navigate("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              {deshboard?.InactiveUser ? deshboard?.InactiveUser : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Inactive User</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  userd userd1  m-5 cursor-pointer"
          onClick={() => navigate("/sale")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              {deshboard?.companyBusiness ? deshboard?.companyBusiness : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Sale</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  userd userd2 m-5 cursor-pointer"
          onClick={() => navigate("/donation-list")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              {" "}
              {deshboard?.totalDonationAmount
                ? deshboard?.totalDonationAmount
                : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Donation Sale</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd5  m-5 cursor-pointer"
          onClick={() => navigate("/sale")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              {deshboard?.totalsale ? deshboard?.totalsale : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Product Sale</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd6 m-5 cursor-pointer"
          // onClick={() => navigate?.push("/users?page=1&status=3")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600"> </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Service Sale</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd7 m-5 cursor-pointer"
          // onClick={() => navigate?.push("/users?page=1&status=4")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600"></div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Subscribe User </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd8 m-5 cursor-pointer"
          onClick={() => navigate("/product")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              {deshboard?.totalProduct ? deshboard?.totalProduct : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Product List</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd3 m-5 cursor-pointer"
          onClick={() => navigate("/donation-list")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              {deshboard?.totalDonation ? deshboard?.totalDonation : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Donation</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd4   m-5 cursor-pointer"
          onClick={() => navigate("/service")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              {deshboard?.totalService ? deshboard?.totalService : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Services</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd1   m-5 cursor-pointer"
          onClick={() => navigate("/service")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              {deshboard?.totalProductStocks
                ? deshboard?.totalProductStocks
                : 0}
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">Total Product Stock</div>
          </div>
        </div>
      </Col>
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10 userd userd1  m-5 cursor-pointer"
          //   onClick={() => navigate?.push("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Today Company Busi.
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.companyBusiness}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          //   onClick={() => navigate?.push("/users")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Today Comp. Busi. With GST
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">
              {deshboard?.companyBusinessWithGst}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          //   onClick={() => navigate?.push("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Stock Amount
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">
              {deshboard?.totalStockProductAmount}
            </div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate?.push("/gpg-ct")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg ct</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgct}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate?.push("/gpg-mq")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">Gpg mq</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgmq}</div>
          </div>
        </div>
      </Col>
      <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
          onClick={() => navigate?.push("/gpg-duqe")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Gpg duqe</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.gpgduqe}</div>
          </div>
        </div>
      </Col> */}
      {/* <Col lg={6} md={24} sm={24} xs={24}>
        <div
          className="flex1 p-10  m-5 cursor-pointer"
        //   onClick={() => navigate?.push("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total admin commission
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.adminWinCommission}</div>
          </div>
        </div>
      </Col> */}

      {/* <Col span={6}>
        <div
          className="flex1 p-10 dashboardBox dashboardBoxShad2 cursor-pointer"
          onClick={() => navigate?.push("/question")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Question
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className="dashbordTextnew">{deshboard?.total_question || 0}</div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default Boxes3;
